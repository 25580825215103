import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'
import { useRouter } from 'next/router'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import useStore from '@/hooks/useStore'

const Banner = () => {
  const { push } = useRouter()
  const data = useStore((state) => state.home?.banner) as Banner

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        py: 6,
        bgcolor: 'primary.main',
        position: 'relative',
        minHeight: '100vh',
        '&::after': {
          display: 'block',
          width: 1,
          height: 1,
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(90deg, rgba(0,0,0,0.8) 50%,transparent)',
        },
      }}
    >
      {data?.media && (
        <Image
          src={getStrapiImage(data?.media)}
          blurDataURL={getStrapiImagePlaceholder(data?.media)}
          placeholder="blur"
          alt="banner"
          layout="fill"
          objectFit="cover"
        />
      )}
      <Container sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={12}>
          <Grid item xs={12} md={7}>
            <Stack spacing={4} sx={{ minHeight: 600 }} justifyContent="center">
              <Typography variant="h3" fontWeight={700} color="#fff">
                {data?.title}
              </Typography>
              <Typography color="#d8d8d8">{data?.description}</Typography>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => push(data?.button?.path)}
                  sx={{ px: 6, py: 2, borderRadius: 50 }}
                >
                  {data?.button?.label}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}></Grid>
        </Grid>
      </Container>
    </Stack>
  )
}

export default Banner
